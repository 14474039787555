var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-access" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form",
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "82px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "企业名称:", prop: "companyId" } },
            [
              _c("companySelect", {
                ref: "companySelect",
                attrs: {
                  clearable: true,
                  disabled: _vm.isEdit,
                  companyTree: _vm.companyList,
                  valueName: _vm.valueNameAdd,
                  value: _vm.valueAdd
                },
                on: { getValue: _vm.getAddGroupId }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "厂家名称:", prop: "makerId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    disabled: _vm.isEdit,
                    clearable: "",
                    placeholder: "请选择终端厂家"
                  },
                  model: {
                    value: _vm.addForm.makerId,
                    callback: function($$v) {
                      _vm.$set(_vm.addForm, "makerId", $$v)
                    },
                    expression: "addForm.makerId"
                  }
                },
                _vm._l(_vm.producerList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "可接入:", prop: "deviceNum" } },
            [
              _c("el-input", {
                attrs: { size: "mini" },
                model: {
                  value: _vm.addForm.deviceNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "deviceNum", $$v)
                  },
                  expression: "addForm.deviceNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "已接入:" } },
            [
              _c("el-input", {
                attrs: { size: "mini", disabled: "" },
                model: {
                  value: _vm.addForm.deviceUsedNum,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "deviceUsedNum", $$v)
                  },
                  expression: "addForm.deviceUsedNum"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "剩余:" } },
            [
              _c("el-input", {
                attrs: {
                  size: "mini",
                  value: _vm.addForm.deviceNum - _vm.addForm.deviceUsedNum,
                  disabled: ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }