
<template>
  <div class="body add-access">
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="82px"
      label-position="left"
      class="demo-form-inline dialog-form"
    >
      <el-form-item label="企业名称:" prop="companyId">
        <companySelect
          ref="companySelect"
          :clearable="true"
          :disabled="isEdit"
          :companyTree="companyList"
          :valueName="valueNameAdd"
          :value="valueAdd"
          @getValue="getAddGroupId"
        ></companySelect>
      </el-form-item>
      <el-form-item label="厂家名称:" prop="makerId">
        <el-select
          v-model="addForm.makerId"
          :disabled="isEdit"
          clearable
          placeholder="请选择终端厂家"
        >
          <el-option
            v-for="item in producerList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可接入:" prop="deviceNum">
        <el-input size="mini" v-model="addForm.deviceNum"></el-input>
      </el-form-item>
      <el-form-item label="已接入:">
        <el-input
          size="mini"
          v-model="addForm.deviceUsedNum"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="剩余:">
        <el-input
          size="mini"
          :value="addForm.deviceNum - addForm.deviceUsedNum"
          disabled
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  queryDictsByCodes,
  addCompanyDevice,
  editCompanyDevice,
  getBusinessCompanyTree,
} from '@/api/lib/api.js'
import companySelect from '@/components/treeSelect/companySelect.vue'

export default {
  components: {
    companySelect
  },
  props: {
    rowData: {
      type: Object,
      default: function (params) {
        return {}
      }
    }
  },
  data () {
    return {
      valueNameAdd: null,
      valueAdd: null,
      addForm: {
        companyId: null,
        deviceNum: 0,
        deviceNum: 0,
        deviceUsedNum: 0,
        makerId: ''
      },
      rules: {
        companyId: [{ required: true, message: '请选择', trigger: 'change' }],
        makerId: [{ required: true, message: '请选择', trigger: 'change' }],
        deviceNum: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      companyList: [],
      producerList: [],
      isEdit: false
    }
  },
  methods: {
    // 新增/修改车辆
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$emit('onLoading', true)
          let data = {
            companyId: this.addForm.companyId,
            deviceNum: this.addForm.deviceNum,
            makerId: this.addForm.makerId
          }
          if (this.isEdit) {
            data.id = this.addForm.id
            editCompanyDevice(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
              }
              this.$emit('onLoading', false)
            })
          } else {
            addCompanyDevice(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.clsoeDia(res.code === 1000)
              } else {
                this.$message.error(res.msg)
              }
              this.$emit('onLoading', false)
            })
          }
        }
      })
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    /** 取消 */
    clsoeDia (flag) {
      this.$emit('close', flag)
    },
    /** 获取数据字典 */
    getDicts () {
      queryDictsByCodes({ parentCodes: 'SBCJ' }).then((res) => {
        if (res.code === 1000) {
          this.producerList = res.data.SBCJ
        }
      })
    }
  },
  created () {
    this.getDicts()
    this.getCompanyList()
  },
  mounted () {
    /** 编辑 */
    if (
      JSON.stringify(this.rowData) !== '{}' &&
      JSON.stringify(this.rowData) !== 'null'
    ) {
      this.addForm = { ...this.rowData }
      delete this.addForm.createTime
      delete this.addForm.updateTime

      this.valueAdd = this.rowData.companyId
      this.valueNameAdd = this.rowData.companyName
      this.isEdit = true
    } else {
      this.isEdit = false
      this.valueAdd = null
      this.valueNameAdd = null
    }
  }
}
</script>

<style lang="scss" scoped>
.add-access {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed("n7");
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
